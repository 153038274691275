import React from 'react'
import styled from 'styled-components'
import { Grid, Item as _Item, Row } from '../components/flexGrid'

const Item = styled(_Item)`
  font-size: 1.8em;
  text-transform: uppercase;
  & > div {
    margin-bottom: 0.5em;
  }
`
const Text = styled.span`
  font-size: 1em;
  font-style: normal;
`

const PressPage = ({ pageContext }) => {
  const { items } = pageContext

  return (
    <Grid>
      {items.map(({ link, text }, idx) => (
        <Row key={idx}>
          <Item>
            <div style={{ marginBottom: '0.5em' }}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={link.media ? `/press/${link.media}` : link.href}
              >
                {link.text}
              </a>
              <Text>&nbsp;{text}</Text>
            </div>
          </Item>
        </Row>
      ))}
    </Grid>
  )
}

export default PressPage
